button {
    font-size: 1.125rem;
    background: #be2a77;
    border: 2px solid #be2a77;
    border-radius: 3px;
    color: white;
    margin: 3px;
    cursor: pointer;
}

button:hover {
    background: #a9256a;
    border: 2px solid #a9256a;
}