.info-panel {
    display: flex;
}
.icon {
    height: 24px;
    width: auto;
    margin-right: 0.5rem;
}

.info-panel h1 {
    font-size: 2rem;
}

.info-panel-right {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.name {

}

.description {
    flex: 1;
}
.icon-strip {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 1rem;
}


.icon-text-pair {
    display: flex;
    align-items: center;
}

.based-on {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
}

.edit-meta-data {
    display: flex;
    flex-direction: column;
}

.button-link {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: #be2a77 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    outline: none !important;
}

.button-link:hover, .button-link:focus {
    color: #be2a77 !important;
    background: none !important;
    outline: none !important;
}

/* Phone */
@media screen and (max-width: 480px) {
    .info-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Tablet */
@media screen and (max-width: 850px) {
    .info-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

