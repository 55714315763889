footer a {
    color: white;
    text-decoration: none;
}

footer a:hover,
footer a.active {
    text-decoration: underline;
}

/* Phone */
@media screen and (max-width: 480px) {
    footer {
        font-size: 0.85rem;
    }
}