* {
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
    overflow: clip;
}


body {
    font-family: corporate-s, Helvetica Neue, Helvetica, Arial, sans-serif;
}