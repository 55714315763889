.nav_list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 100%;
}

.nav_list a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
}

.nav_list a:hover,
.nav_list a.active {
    text-decoration: underline;
}

.small {
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.logo-image {
    max-height: 70%;
    margin-left: 0.5rem;
    margin-top: 0.75rem;
    cursor: pointer;
}

/* Phone */
@media screen and (max-width: 480px) {
    .nav_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 0.90rem;
    }
}

@media screen and (max-width: 850px) {
    .nav_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 0.90rem;
    }
}