.preview {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: 92%;
    background-color: #f0f0f0;
    border-radius: 10px;
    position: relative;
}

.preview:hover {
    cursor: pointer;
}

.image-container {
    position: relative; /* Add relative positioning to the image container */
}

.overlay-digits {
    position: absolute;
    bottom: 9px; /* Adjust the bottom offset as needed */
    right: 4px; /* Adjust the right offset as needed */
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 4px 8px 2px 8px;
    border-radius: 10px;
    font-size: 1rem;
}

.preview-name {
    font-family: "Frank Ruhl Libre", Georgia, serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 1rem;
    display: block;
}

.icon {
    height: 20px;
    width: auto;
    margin-right: 0.5rem;
}

.preview-img {
    border-radius: 10px;
    height: auto;
    width: 100%;
}

.cooked {
    padding-left: 10px;
    font-family: corporate-s, Helvetica Neue, Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 480px) {
    .preview-name {
        font-size: 1rem;
        padding: 0.5rem;
    }
    .icon {
        display: none;
    }
    .overlay-digits {
        font-size: 0.9rem;
    }
}
