.hero-container {
    grid-area: 2 / 1 / 9 / 7;
    overflow: hidden;
    position: relative;
    cursor: pointer;

}
.hero-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image {

}

.hero-card {
    background-color: rgba(255, 255, 255, 0.75);
    text-align: center;


    position: absolute;
    left: 50%;
    --webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 50%;


    bottom: 20rem;
}

.hero-card-inner {
    border: 2px solid #0c5f5f;
    margin: 15px;

    display: flex;
    flex-direction: column;
}

.hero-card-name {
    font-family: "Frank Ruhl Libre", Georgia, serif;
    font-size: 2rem;
    padding: 0.5rem 1rem 0 1rem;
}

.hero-card-description {
    padding: 1rem;
}

/* Tablet */
@media screen and (max-width: 850px) {
    .hero-image-container {
        grid-area: 1 / 1 / 9 / 7;
        overflow: hidden;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hero-image {
        overflow: hidden;
        width: 100%;
    }

    .hero-card {
        background-color: rgba(255, 255, 255, 0.75);
        text-align: center;


        position: absolute;
        left: 50%;
        --webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 60%;

        bottom: 0.5rem;

    }

    .hero-card-description {
        display: none;
    }

    .hero-card-name {
        font-family: "Frank Ruhl Libre", Georgia, serif;
        font-size: 1.5rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
}

/* Phone */
@media screen and (max-width: 480px) {
    .hero-container {
        grid-area: 2 / 1 / 6 / 11;
        height: 100%;
        overflow: hidden;
        position: relative;
    }
    .hero-image-container {
        grid-area: 2 / 1 / 6 / 11;
    }

    .hero-image {
        overflow: hidden;
        width: 100%;
    }

    .hero-card {
        background-color: rgba(255, 255, 255, 0.75);
        text-align: center;


        position: absolute;
        left: 50%;
        --webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 75%;


        bottom: 0.5rem;

    }

    .hero-card-name {
        font-family: "Frank Ruhl Libre", Georgia, serif;
        font-size: 1.4rem;
        padding: 0.5rem 1rem 1rem 1rem;
    }

    .hero-card-description {
        display: none;
    }
}