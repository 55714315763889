/* GRID LAYOUTS */
/* Light Blues = #72aeb2 */
.parent {
    display: grid;
    grid-template-columns: repeat(10, 10%);
    grid-template-rows: repeat(10, 10%);
    grid-column-gap: 0;
    grid-row-gap: 0;
    height: 100%;
}

.header {
    grid-area: 1 / 1 / 2 / 11;
    height: 100%;
    background-color: #0c5f5f;
    z-index: 1;
}

.navigation {
    grid-area: 1 / 4 / 2 / 8;
    height: 100%;
    z-index: 2;
}

.logo {
    grid-area: 1 / 1 / 2 / 3;
    height: 100%;
    z-index: 3;
}

.hamburger-container {
    display: none;
}

.content {
    grid-area: 2 / 4 / 10 / 8;
    height: 100%;
    overflow: auto;
}

.home-content {
    grid-area: 2 / 1 / 10 / 11;
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
    align-items: center;
}

.home-previews {
    grid-area: 1 / 7 / 9 / 11;
}

.content {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.footer {
    grid-area: 10 / 1 / 11 / 11;
    background-color: #0c5f5f;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

/* END OF GRID LAYOUTS */
html,
body {
    height: 100%;
    margin: 0;
}

a {
    color: #be2a77;
}

ul {
    list-style-type: none;
}

.information {
    background-color: #f0f0f0;
    padding: 0.5rem;
    border-radius: 10px;
}

.description {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 1rem;
}

.curly_text, h1, a {
    font-family: "Frank Ruhl Libre", Georgia, serif;
}

h1, h2, h3 {
    padding: 1rem;
    font-weight: normal;
    text-align: center;
}

hr {
    color: #ffffff;
}

.left_align {
    text-align: left;
    padding-left: 0;
}

/* Tablet */
@media screen and (max-width: 850px) {
    .header {
        grid-area: 1 / 1 / 2 / 11;
        height: 100%;
        background-color: #0c5f5f;
    }
    .content {
        grid-area: 2 / 1 / 10 / 11;
        height: 100%;
        overflow: auto;
        padding: 5px;
    }
    .logo {
        grid-area: 1 / 4;
        height: 100%;
    }
    .navigation {
        display: none;
    }
    .home-content {
        grid-area: 2 / 1 / 10 / 11;
        height: 100%;

        display: flex;
        flex-direction: column;

    }
    .home-previews {
        overflow: hidden;
        height: 100%;

        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
    }
    .home-previews::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    .hamburger-container {
        display: block;
    }
}

/* Phone */
@media screen and (max-width: 480px) {
    .header {
        grid-area: 1 / 1 / 2 / 11;
        height: 100%;
        background-color: #0c5f5f;
    }
    .content {
        grid-area: 2 / 1 / 11 / 11;
        height: 100%;
        overflow: auto;
        padding: 5px;
    }
    .logo {
        grid-area: 1 / 3;
    }
    .navigation {
        display: none;
    }

    .hamburger-container {
        display: block;
    }
    .footer {
        display: none;
    }
    .home-content {
        grid-area: 2 / 1 / 11 / 11;
    }
    .home-previews {
        grid-area: 6 / 1 / 11 / 11;
        overflow: hidden;
        height: 100%;

        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
    }
    .home-previews::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
}



